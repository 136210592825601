.service-card {
  perspective: 1000px;
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-card-inner {
  position: relative;
  width: 93%;
  height: 99%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-card:hover .service-card-inner {
  transform: rotateY(180deg);
}

.service-card-front, .service-card-back {
  position: absolute;
  width: 100%;
  height: 90%;
  backface-visibility: hidden;
  border-radius: 40px;
  overflow: hidden;
}

.service-card-front {
  background-size: cover;
  background-position: center;
}

.service-card-back {
  background-color: #162635;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  transform: rotateY(180deg);
  border: 2px solid white;
  font-size: 1.2rem;
  flex-direction: column;
  height: 100%;
  
}

.service-card-back p {
  margin: 0;
}

.service-card.long {
  grid-column: span 3;
}

@media (max-width: 1340px) {
  .service-card {
    height: 180px;
  }

  .service-card-back {
    font-size: 0.9rem;
    padding: 15px;
  }
}

@media (max-width: 1200px) {
  .service-card {
    height: 180px;
  }

  .service-card-back {
    font-size: 0.9rem;
    padding: 15px;
  }
}

@media (max-width: 868px) {
  .service-card {
    height: 160px;
  }

  .service-card-back {
    font-size: 0.8rem;
    padding: 10px;
  }
}

@media (max-width: 680px) {
  .service-card {
    height: 140px;
  }

  .service-card-back {
    font-size: 0.7rem;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .service-card {
    height: 120px;
  }

  .service-card-back {
    font-size: 0.6rem;
    padding: 6px;
    overflow-y: auto;
    flex-direction: column;
  }
}

@media (max-width: 3500px) {
  .services-grid {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
  }

  

  .service-card {
    height: 220px;
  }

  .service-card.long {
    height: 220px;
  }
}

@media (max-width: 2300px) {
  .services-grid {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
  }

  .service-card {
    height: 220px;
  }

  .service-card.long {
    height: 220px;
  }
}

@media (max-width: 1680px) {
  .services-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }

  .service-card {
    height: 220px;
  }

  .service-card.long {
    height: 220px;
  }
}

@media (max-width: 1420px) {
  .services-grid {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 30px;
  }

  .service-card {
    height: 210px;
  }

  .service-card.long {
    height: 210px;
  }
}

@media (max-width: 1340px) {
  .services-grid {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 30px;
  }

  .service-card {
    height: 200px;
  }

  .service-card.long {
    height: 200px;
  }
}

@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .service-card {
    height: 180px;
  }

  .service-card.long {
    grid-column: span 1;
    height: 180px;
  }
}

@media (max-width: 868px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
  }

  .service-card {
    height: 160px;
    width: 300px;
  }

  .service-card.long {
    grid-column: span 1;
    height: 160px;
  }
}

@media (max-width: 680px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
  }

  .service-card {
    height: 160px;
    width: 290px;
  }

  .service-card.long {
    grid-column: span 1;
    height: 140px;
  }
}

@media (max-width: 540px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
  }

  .service-card {
    height: 160px;
    width: 250px;
  }

  .service-card.long {
    grid-column: span 1;
    height: 140px;
  }
}

@media (max-width: 480px) {
  .services-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 2px;
  }

  .service-card {
    height: 150px;
    width: 300px;
  }

  .service-card.long {
    grid-column: span 1;
    height: 120px;
  }
}