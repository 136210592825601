@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.content p{
  text-align: justify;
}
.timeline-item {
  opacity: 0;
  animation: fadeInLeft 1s ease forwards;
}

.timeline-item:nth-child(odd) {
  animation-delay: 0.3s;
}

.timeline-item:nth-child(even) {
  animation-delay: 0.6s;
}

.projects-section {
  padding: 40px;
  background-color: #0F1E12;
  color: #fff;
  text-align: center;
  padding-top: 120px;
}

.projects-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.tabs {
  margin-bottom: 20px;
}

.tabs button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #1e3a4c;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.tabs button.active,
.tabs button:hover {
  background-color: #fff;
  color: #1e3a4c;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 6px;
  background-color: #fff;
  transform: translateX(-50%);
  z-index: 0;
}

.section-title-obras {
  font-size: 2em;
  margin: 40px 0 20px;
  text-align: center;
  color: #fff;
  position: relative;
  background-color: #0F1E12;
  z-index: 1;
  padding: 30px;
  border: 2px solid #fff;
  border-radius: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.timeline-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 20px 0;
  text-align: left;
}

.timeline-item.left {
  flex-direction: row-reverse;
}

.timeline-item .project-info,
.timeline-item .image-container {
  width: 600px; /* Set the width of the containers */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;
  text-align: justify;
}

.timeline-item .project-info .content,
.timeline-item .image-container .content {
  max-width: 400px; /* Limit the content width to 400px */
  width: 100%; /* Ensure the content takes the full available width */
}

.timeline-item.left .project-info {
  text-align: left;
}

.timeline-item.right .project-info {
  text-align: left;
}

.timeline-item .image-container img {
  width: 100%;
  height: auto;
  object-fit: contain; /* Ensure images maintain their scale */
}

.project-info h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.project-info p {
  font-size: 1em;
  line-height: 1.5;
}

.download-btn {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #1e3a4c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.download-btn:hover {
  background-color: #fff;
  color: #1e3a4c;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .timeline-item {
    flex-direction: column !important;
    align-items: center;
    margin: 40px 0;
    border: 1px solid #fff; /* Add border to each project */
    border-radius: 10px; /* Optional: Add border radius */
    padding: 10px; /* Optional: Add padding inside the border */
  }

  .timeline-item .project-info,
  .timeline-item .image-container {
    width: 100%;
    text-align: center;
    padding: 20px;
  }

  .timeline-item .project-info .content,
  .timeline-item .image-container .content {
    max-width: 90%;
  }

  .timeline:before {
    display: none; /* Hide the line for smaller screens */
  }

  .project-info h3 {
    font-size: 1.2em;
  }

  .project-info p {
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .projects-section {
    padding: 20px;
    padding-top: 110px;
  }

  .projects-section h2 {
    font-size: 1.8em;
    margin-bottom: 30px;
  }

  .tabs {
    margin-bottom: 20px;
  }

  .tabs button {
    margin: 0 5px;
    padding: 8px 16px;
    font-size: 0.9em;
  }

  .timeline {
    padding: 20px 10px;
  }

  .timeline:before {
    display: none; /* Hide the line for smaller screens */
  }

  .timeline-item {
    flex-direction: column !important;
    align-items: center;
    text-align: center; /* Center text for smaller screens */
    border: 1px solid #fff; /* Add border to each project */
    border-radius: 10px; /* Optional: Add border radius */
    padding: 10px; /* Optional: Add padding inside the border */
  }

  .timeline-item .project-info,
  .timeline-item .image-container {
    max-width: 100%;
    padding: 20px;
  }

  .timeline-item .project-info .content,
  .timeline-item .image-container .content {
    max-width: 100%;
  }

  .project-info h3 {
    font-size: 1.2em;
  }

  .project-info p {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .projects-section {
    padding: 15px;
    padding-top: 110px;
  }

  .projects-section h2 {
    font-size: 1.5em;
    margin-bottom: 30px;
  }

  .tabs {
    margin-bottom: 15px;
  }

  .tabs button {
    margin: 0 3px;
    padding: 6px 12px;
    font-size: 0.8em;
  }

  .timeline:before {
    display: none; /* Hide the line for smaller screens */
  }

  .timeline-item {
    flex-direction: column !important;
    align-items: center;
    text-align: center; /* Center text for smaller screens */
    border: 1px solid #fff; /* Add border to each project */
    border-radius: 10px; /* Optional: Add border radius */
    padding: 10px; /* Optional: Add padding inside the border */
  }

  .timeline-item .project-info,
  .timeline-item .image-container {
    max-width: 100%;
    padding: 10px;
    text-align: center;
  }

  .timeline-item .project-info .content,
  .timeline-item .image-container .content {
    max-width: 100%;
  }

  .project-info h3 {
    font-size: 1em;
  }

  .project-info p {
    font-size: 0.8em;
  }
}
